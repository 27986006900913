<template>
  <v-row>
    <v-col cols="12">
      <v-card flat class="pa-4">
        <v-card-title class="d-block px-0">
          <back-button-component/>
          <div class="d-flex">
            <span class="font-weight-bold d-block">Calendar <span v-if="type === 'month'">for {{ title }}</span></span>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
              <v-btn
                  v-if="type === 'month'"
                  icon
                  class="ma-2"
                  @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-select
                  v-model="type"
                  :items="types"
                  dense
                  hide-details
                  class="ma-2"
                  label="Type"
              ></v-select>
              <v-btn
                  v-if="type === 'month'"
                  icon
                  class="ma-2"
                  @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card rounded-lg>
          <v-card-text v-if="type === 'month'">
            <div>
              <v-sheet height="600">
                <v-calendar
                    locale="en"
                    ref="calendar"
                    v-model="value"
                    :weekdays="weekday"
                    type="month"
                    :events="events"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    @click:event="showReport"
                    @change="getEvents"
                >
                </v-calendar>
              </v-sheet>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col cols="4" v-for="(month,idx) in months " :key="idx">
                <v-sheet>
                  <h3 class="text-left my-3">
                    {{ getMonthName(months[idx]) }}
                  </h3>
                  <v-sheet height="250">
                    <v-calendar
                        locale="en"
                        ref="calendar[m]"
                        class="year-calendar"
                        v-model="value"
                        :weekdays="weekday"
                        type="month"
                        :start="month"
                        :events="events"
                        :event-more="false"
                        :event-overlap-threshold="30"
                        :event-color="getEventColor"
                        @change="getEvents(month)"
                        @click:event="showReport"
                    >
                    </v-calendar>
                  </v-sheet>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>

export default {
  data () {
    return {
      title: null,
      type: 'month',
      types: [
        {
          text: 'Month',
          value: 'month'
        },
        {
          text: 'Year',
          value: 'year'
        }
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],
      months: [
        new Date().getFullYear() + '-01-01',
        new Date().getFullYear() + '-02-01',
        new Date().getFullYear() + '-03-01',
        new Date().getFullYear() + '-04-01',
        new Date().getFullYear() + '-05-01',
        new Date().getFullYear() + '-06-01',
        new Date().getFullYear() + '-07-01',
        new Date().getFullYear() + '-08-01',
        new Date().getFullYear() + '-09-01',
        new Date().getFullYear() + '-10-01',
        new Date().getFullYear() + '-11-01',
        new Date().getFullYear() + '-12-01',
      ]
    }
  },
  mounted() {
    this.title = this.$refs.calendar.title
  },
  methods: {
    getMonthName(dtStr) {
      const date = new Date(dtStr)
      if (date.toString() !== 'Invalid Date')
        return date.toLocaleString('en-us', { month: 'long' })
      else
        return 'December'
    },
    showReport ({ event }) {
      this.$router.push({ name: 'dashboard.report.show', params: { id: event.id } })
    },
    // eslint-disable-next-line no-unused-vars
    getEvents (month = null) {
      this.events = []
      this.$http.get(`${process.env.VUE_APP_API_URL}/calendar`)
          .then(response => {
            response.data.forEach( item => {
              if (this.getMonthName(month) === item['month_name']) {
                this.events.push({
                  id: item.id,
                  start: new Date(item['created_at']),
                  end: new Date(item['created_at']),
                  color: item.status.color,
                  timed: false
                })
              }
            })
      })
    },
    getEventColor (event) {
      return event.color
    }
  },
}
</script>